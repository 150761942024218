import Vue from "vue";
import Router from "vue-router";
import Store from "@/store/index.js";
import * as utils from "@/utils";

// レイアウト
const LoginLayout = () => import("@/layouts/LoginLayout.vue");
const MainLayout = () => import("@/layouts/MainLayout.vue");

// ページ
const TabletAuth = () => import("@/views/TabletAuth.vue"); // 認証画面
const TabletLogin = () => import("@/views/TabletLogin.vue"); // ログイン画面
const TabletMain = () => import("@/views/TabletMain.vue"); // メイン画面
const TabletOrder = () => import("@/views/TabletOrder.vue"); // 予約画面
const TabletRecept = () => import("@/views/TabletRecept.vue"); // 受取画面
const NotFound = () => import("@/views/NotFound.vue"); // 404エラー
const NotSupport = () => import("../views/NotSupport.vue"); // 動作対象外

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: LoginLayout,
      children: [
        {
          path: "",
          component: TabletAuth,
          name: "TabletAuth",
        },
        {
          path: "login",
          component: TabletLogin,
          name: "TabletLogin",
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "main",
          component: TabletMain,
          name: "TabletMain",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/notsupport",
          component: NotSupport,
          name: "NotSupport",
          meta: {
            requiresAuth: false,
          },
        },
      ],
    },
    {
      path: "/",
      component: MainLayout,
      children: [
        {
          path: "order",
          component: TabletOrder,
          name: "TabletOrder",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "recept",
          component: TabletRecept,
          name: "TabletRecept",
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },

    {
      path: "/:notFound(.*)",
      component: NotFound,
      name: "NotFound",
    },
  ],
});

router.beforeEach((to, from, next) => {
  // 画面解像度判定
  const validDisplaySize = utils.checkDisplaySize();
  // ウィンドウサイズ判定
  const validWindowSize = utils.checkWindowSize();
  // タッチデバイス判定
  const validTouchDevice = utils.checkTouchDevice();
  // ブラウザ判定
  const validBrowser = utils.checkBrowser();
  // 遷移先を判定
  if (to.name != "NotSupport") {
    // 遷移先が動作対象外画面以外の場合
    // 画面解像度を判定
    if (process.env.VUE_APP_CHECK_DISPLAY == "1" && !validDisplaySize) {
      // 画面解像度が足りない場合、動作対象外画面に遷移
      next({ name: "NotSupport" });
      console.log("画面解像度不足 -> 動作対象外画面へ");
      return;
    }
    // ウィンドウサイズを判定
    if (process.env.VUE_APP_CHECK_WINDOW == "1" && !validWindowSize) {
      // ウィンドウサイズが足りない場合、動作対象外画面に遷移
      next({ name: "NotSupport" });
      console.log("ウィンドウサイズ不足 -> 動作対象外画面へ");
      return;
    }
    // タッチ対応を判定
    if (process.env.VUE_APP_CHECK_TOUCH == "1" && !validTouchDevice) {
      // タッチ非対応の場合、動作対象外画面に遷移
      next({ name: "NotSupport" });
      console.log("タッチ非対応 -> 動作対象外画面へ");
      return;
    }
    // ブラウザを判定
    if (process.env.VUE_APP_CHECK_BROWSER == "1" && !validBrowser) {
      // ブラウザが非対応の場合、動作対象外画面に遷移
      next({ name: "NotSupport" });
      console.log("ブラウザ非対応 -> 動作対象外画面へ");
      return;
    }
    // 遷移先が要認証画面であるか判定
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      // 遷移先が要認証画面の場合、ログイン状態を判定
      if (!Store.state.isLogin) {
        next({
          path: "/",
          query: {
            redirect: to.fullPath,
          },
        });
        console.log("未ログイン -> 認証画面へ");
        return;
      } else {
        // ログイン済みの場合、そのまま遷移
        next();
        console.log("ログイン済 -> 指定された画面へ");
        return;
      }
    } else {
      next();
      console.log("要認証画面外 -> 指定された画面へ");
      return;
    }
  } else {
    // 遷移先が動作対象外画面の場合
    // 画面解像度を判定
    if (process.env.VUE_APP_CHECK_DISPLAY == "1" && !validDisplaySize) {
      // 画面解像度が足りない場合、動作対象外画面に遷移
      next();
      console.log("画面解像度不足 -> 動作対象外画面へ");
      return;
    }
    // ウィンドウサイズを判定
    if (process.env.VUE_APP_CHECK_WINDOW == "1" && !validWindowSize) {
      // ウィンドウサイズが足りない場合、動作対象外画面に遷移
      next();
      console.log("ウィンドウサイズ不足 -> 動作対象外画面へ");
      return;
    }
    // タッチ対応を判定
    if (process.env.VUE_APP_CHECK_TOUCH == "1" && !validTouchDevice) {
      // タッチ非対応の場合、動作対象外画面に遷移
      next();
      console.log("タッチ非対応 -> 動作対象外画面へ");
      return;
    }
    // ブラウザを判定
    if (process.env.VUE_APP_CHECK_BROWSER == "1" && !validBrowser) {
      // ブラウザが非対応の場合、動作対象外画面に遷移
      next();
      console.log("ブラウザ非対応 -> 動作対象外画面へ");
      return;
    }
    next({ name: "TabletAuth" });
    console.log("動作対象 -> 認証画面へ");
    return;
  }
});

export default router;
