import instance from "./instance";
// import { sleep } from "@/utils";

// ****************************************
// 機能：APIエンドポイント（GETリクエスト）
// 引数：パラメータ（オブジェクト）
// ****************************************
export async function getRequest(url, params) {
  // await sleep(200);
  return await instance.get(url, { params }).then(function (response) {
    return response;
  });
}

// ****************************************
// 機能：APIエンドポイント（POSTリクエスト）
// 引数：パラメータ（URLSearchParams）
// ****************************************
export async function postRequest(url, params) {
  // await sleep(200);
  return await instance.post(url, params).then(function (response) {
    return response;
  });
}
