<!--------------------------------------------------
  システム名：弁当注文ビフォサック（タブレット版）
  プログラム名：SystemBar.vue
  モジュール名：システムバー
  備考：
-------------------------------------------------->
<template>
  <v-system-bar app>
    <!-- バージョン情報 -->
    <span>
      <strong>Ver. {{ $version }}</strong>
    </span>
    <!-- 接続状態 -->
    <span class="mx-2">
      <!-- APIサーバー -->
      <v-tooltip bottom :color="isServer ? 'success' : 'grey'">
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="mx-1" :color="isServer ? 'success' : 'grey'" style="font-size: 1.4rem" v-bind="attrs" v-on="on">language</v-icon>
        </template>
        <span v-if="isServer">APIサーバー：接続済</span>
        <span v-if="!isServer">APIサーバー：未接続</span>
      </v-tooltip>
      <!-- データベース -->
      <v-tooltip bottom :color="isDatabase ? 'success' : 'grey'">
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="mx-1" :color="isDatabase ? 'success' : 'grey'" style="font-size: 1.4rem" v-bind="attrs" v-on="on">storage</v-icon>
        </template>
        <span v-if="isDatabase">データベース：接続済</span>
        <span v-if="!isDatabase">データベース：未接続</span>
      </v-tooltip>
      <!-- WebSocketサーバー -->
      <v-tooltip bottom :color="isWebSocket ? 'success' : 'grey'">
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="mx-1" :color="isWebSocket ? 'success' : 'grey'" style="font-size: 1.4rem" v-bind="attrs" v-on="on">settings_ethernet</v-icon>
        </template>
        <span v-if="isWebSocket">WebSocketサーバー：接続済</span>
        <span v-if="!isWebSocket">WebSocketサーバー：未接続</span>
      </v-tooltip>
      <!-- カードリーダー -->
      <v-tooltip bottom :color="isCardReader ? 'success' : 'grey'">
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="mx-1" :color="isCardReader ? 'success' : 'grey'" style="font-size: 1.4rem" v-bind="attrs" v-on="on">badge</v-icon>
        </template>
        <span v-if="isCardReader">カードリーダー：接続済</span>
        <span v-if="!isCardReader">カードリーダー：未接続</span>
      </v-tooltip>
    </span>
    <v-spacer></v-spacer>
    <!-- 現在日時 -->
    <span>
      <strong>{{ nowTime }}</strong>
    </span>
  </v-system-bar>
</template>

<script>
import * as api from "@/api/client";
import dayjs from "dayjs";

export default {
  name: "SystemBar",
  data: () => ({
    nowTime: "",
    isServer: false,
    isDatabase: false,
    isCardReader: false,
    isWebSocket: false,
  }),
  methods: {
    // 接続チェック
    checkStatus() {
      this.checkServer();
      this.checkDatabase();
      this.connectWebSocket();
    },

    // サーバー接続チェック
    async checkServer() {
      try {
        const responce = await api.getCheckServer();
        this.isServer = responce.data["result"];
      } catch {
        this.isServer = false;
      }
    },

    // データベース接続チェック
    async checkDatabase() {
      try {
        const responce = await api.getCheckDB();
        this.isDatabase = responce.data["result"];
      } catch {
        this.isDatabase = false;
      }
    },

    // カードリーダー接続チェック
    async connectWebSocket() {
      const ws = new WebSocket(`ws://localhost:${process.env.VUE_APP_WEBSOCKET_PORT}`);

      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log(data);

        // Pasoriの接続状態を更新
        if (data.message && data.connected) {
          this.isCardReader = data.connected;
          console.log(data.message);
        }
      };

      ws.onopen = () => {
        this.isWebSocket = true;
        console.log("WebSocket接続成功");
      };
      ws.onerror = (err) => console.error("WebSocketエラー", err);
      ws.onclose = () => {
        this.isWebSocket = false;
        this.isCardReader = false;
        console.log("WebSocket切断");
      };
    },

    timeFormate(timeStamp) {
      let nowYear = dayjs(timeStamp).format("YYYY");
      let nowMonth = dayjs(timeStamp).format("MM");
      let nowDay = dayjs(timeStamp).format("DD");
      let nowHour = dayjs(timeStamp).format("HH");
      let nowMin = dayjs(timeStamp).format("mm");
      let nowSec = dayjs(timeStamp).format("ss");

      // システムバー時刻
      this.nowTime = nowYear + "/" + nowMonth + "/" + nowDay + " " + nowHour + ":" + nowMin + ":" + nowSec;
    },
    nowTimes() {
      this.timeFormate(new Date());
      setTimeout(() => {
        this.nowTimes();
      }, 5000);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.checkStatus();
      this.nowTimes();
    });
  },
};
</script>
